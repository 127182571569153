import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { OxHeaderNavLink } from "src/components/OxStickyHeader/components/OxHeaderNavLink";
import * as Styled from "./OxHeaderNavLinks.styled";

export const OxHeaderNavLinks = (): JSX.Element => {
  const {
    pages: { nodes: pages },
  } = useStaticQuery(
    graphql`
      query {
        pages: allSanityPage(
          sort: { fields: [order], order: ASC }
          filter: {
            slug: { current: { ne: null } }
            enabled: { eq: true }
            show_in_header: { eq: true }
          }
        ) {
          nodes {
            name
            slug {
              current
            }
          }
        }
      }
    `
  );

  return (
    <Styled.Links>
      {pages.map((page, index: number) => (
        <OxHeaderNavLink key={index} to={page.slug.current} title={page.name} />
      ))}
    </Styled.Links>
  );
};
