import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

import { TBreakpoint } from "src/types/styles.types";
import theme from "src/config/theme";

export type TContext = {
  [key in TBreakpoint]?: boolean;
};

const defaultValue = {};
const BreakpointContext = createContext<TContext>(defaultValue);
const queries = theme.breakpoints.keys.reduce(
  (acc: { [key: string]: string }, key) => {
    acc[key] = theme.breakpoints.only(key).replace("@media", "");
    return acc;
  },
  {}
);

const BreakpointProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists: { [key: string]: MediaQueryList } = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = (): void => {
      const updatedMatches = keys.reduce(
        (acc: { [key: string]: boolean }, media) => {
          acc[media] = !!(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          );
          return acc;
        },
        {}
      );
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: { [key: string]: boolean } = {};
      keys.forEach(media => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach(media => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return (): void => {
      if (isAttached) {
        keys.forEach(media => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoints(): TContext {
  const context = useContext<TContext>(BreakpointContext);
  if (context === defaultValue) {
    throw new Error("useBreakpoints must be used within BreakpointProvider");
  }
  return context;
}
export { useBreakpoints, BreakpointProvider };
