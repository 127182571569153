import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { TThemedInputContext } from "src/context/ThemedInputContext";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";
import { moveLeftBack } from "src/styles/animations";
import { OxSpinner } from "src/components/OxSpinner";
import { OxLink } from "src/components/OxLink";

type TSharedProps = {
  icon?: boolean;
  $contextTheme: TThemedInputContext;
  disabled?: boolean;
  isAnchor?: boolean;
};

const sharedStyles = ({
  icon,
  $contextTheme,
  disabled,
  isAnchor,
}: TSharedProps) => css`
  ${fluidSizing([{ prop: "padding-left", values: [15, 15, 15] }])};
  border-width: 1px;
  border-style: solid;
  transition-duration: 300ms;
  color: ${$contextTheme.color};
  border-color: ${$contextTheme.borderColor};
  background-color: ${$contextTheme.backgroundColor};
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Medium,
    [10, 10, 10],
    [18, 18, 18]
  )}
  &:hover${!isAnchor && ":enabled"} {
    color: ${$contextTheme.hoverColor};
    border-color: ${$contextTheme.hoverBorderColor};
    background-color: ${$contextTheme.hoverBackgroundColor};

    ${disabled &&
    css`
      cursor: default;
    `}

    svg {
      ${icon &&
      css`
        animation: ${moveLeftBack} 1.5s;
      `}
    }
  }

  ${disabled &&
  css`
    cursor: default;
  `}

  ${!icon &&
  css`
    ${fluidSizing([
      { prop: "height", values: [30, 30, 30] },
      { prop: "border-radius", values: [15, 15, 15] },
      { prop: "min-width", values: [190, 190, 190] },
      { prop: "padding-right", values: [15, 15, 15] },
    ])}
  `}
    ${icon &&
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${fluidSizing([
      { prop: "height", values: [30, 30, 30] },
      { prop: "border-radius", values: [15, 15, 15] },
      { prop: "min-width", values: [140, 140, 140] },
      { prop: "padding-right", values: [15, 15, 15] },
    ])}
    svg {
      ${fluidSizing([{ prop: "height", values: [10, 10, 10] }])}
    }
  `}
`;

export const Button = styled.button<TSharedProps>(
  ({ $contextTheme, icon, disabled }) =>
    sharedStyles({ $contextTheme, icon, disabled })
);

export const Link = styled(OxLink)<TSharedProps>(
  ({ $contextTheme, icon, disabled }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    ${sharedStyles({ $contextTheme, icon, disabled, isAnchor: true })};
  `
);

export const Spinner = styled(OxSpinner)<{
  $contextTheme: TThemedInputContext;
}>(
  ({ $contextTheme }) => css`
    ${fluidSizing([
      { prop: "width", values: [16, 16, 16] },
      { prop: "height", values: [16, 16, 16] },
      {
        prop: "border",
        values: [3, 3, 3],
        formatter: (val): string =>
          `${val}vw solid ${$contextTheme.spinnerColor}`,
      },
      {
        prop: "border-top",
        values: [3, 3, 3],
        formatter: (val): string => `${val}vw solid transparent`,
      },
    ])}
  `
);
