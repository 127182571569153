import * as Styled from "./OxMenuSocial.styled";

import { OxGetInTouch } from "src/components/OxGetInTouch";
import React from "react";
import { TMenuAnimationOptions } from "src/components/OxMenu";

type TProps = TMenuAnimationOptions;

export const OxMenuSocial = ({
  show,
  animationTime,
  ...props
}: SCProps<"div", TProps>): JSX.Element => {
  return (
    <Styled.Container show={show} animationTime={animationTime} {...props}>
      <OxGetInTouch compressed />
    </Styled.Container>
  );
};
