import * as Styled from "./OxIcon.styled";

import {
  ICONS,
  SVGItem,
  TCircle,
  TEllipse,
  TRectangle
} from "src/constants/icons";
import React from "react";

type TProps = {
  name: string;
  title?: string;
  size?: number | string;
};

export const OxIcon = ({
  size,
  name,
  title,
  ...props
}: SCProps<"svg", TProps>): JSX.Element | null => {
  const icon = ICONS[name];
  if (!icon) return null;

  const getSVGInnerEl = (item: SVGItem, index: number) => {
    return (
      <React.Fragment key={index}>
        {item.tag === "path" && (
          <path
            d={item.value as string}
            fill={item.fill || "currentColor"}
            {...item.attrs}
          />
        )}
        {item.tag === "circle" && (
          <circle
            cx={(item.value as TCircle).cx}
            cy={(item.value as TCircle).cy}
            r={(item.value as TCircle).r}
            transform={(item.value as TCircle).transform}
            fill={item.fill || "currentColor"}
            {...item.attrs}
          />
        )}
        {item.tag === "ellipse" && (
          <ellipse
            cx={(item.value as TEllipse).cx}
            cy={(item.value as TEllipse).cy}
            rx={(item.value as TEllipse).rx}
            ry={(item.value as TEllipse).ry}
            fill={item.fill || "currentColor"}
            {...item.attrs}
          />
        )}
        {item.tag === "rect" && (
          <rect
            width={(item.value as TRectangle).width}
            height={(item.value as TRectangle).height}
            fill={item.fill || "currentColor"}
            {...item.attrs}
          />
        )}
        {item.tag === "polygon" && (
          <polygon
            points={item.value as string}
            fill={item.fill || "currentColor"}
          />
        )}
        {item.tag === "g" && (
          <g {...item.attrs}>{(item.value as SVGItem[]).map(getSVGInnerEl)}</g>
        )}
      </React.Fragment>
    );
  };

  return (
    <Styled.SVG viewBox={icon.viewBox} size={size} {...props}>
      <title>{title ? title : icon.title ? icon.title : name}</title>
      {icon.items.map(getSVGInnerEl)}
    </Styled.SVG>
  );
};
