import { OxLink } from "src/components/OxLink";
import styled, { css } from "styled-components";
import { createTextStyle } from "src/utils/createTextStyle";

export const Link = styled(OxLink)(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    text-transform: uppercase;

    ${createTextStyle(
      theme.fonts.secondary,
      theme.fontWeights.normal,
      [10, 10, 10],
      [18, 18, 18]
    )}
  `
);
