import React from "react";

export type TLayoutContext = {
  menuIsOpen: boolean;
  websiteLoaderActive: boolean;
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalId: string | null;
  setModalId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const LayoutContext = React.createContext({} as TLayoutContext);
