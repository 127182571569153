import { EFontWeight, EFonts } from "src/config/enums";
import { FlattenSimpleInterpolation, css } from "styled-components";

import { fluidSizing } from "./fluidSizing";

export const createTextStyle = (
  family: EFonts,
  weight: EFontWeight,
  fontSize: number[],
  lineHeight: number[],
  important?: boolean
): FlattenSimpleInterpolation => {
  return css`
    font-family: ${family};
    font-weight: ${weight};
    ${fluidSizing([
      { prop: "font-size", values: fontSize, important },
      { prop: "line-height", values: lineHeight, important }
    ])}
  `;
};
