import * as Styled from "./OxMenu.styled";

import { OxIcon } from "src/components/OxIcon";
import { OxMenuAsset } from "src/components/OxMenuAsset";
import { OxMenuNavigation } from "src/components/OxMenuNavigation";
import { OxMenuNewsletter } from "src/components/OxMenuNewsletter";
import { OxMenuSocial } from "src/components/OxMenuSocial";
import React from "react";

export type TMenuAnimationOptions = {
  show: boolean;
  animationTime: number; // Miliseconds
};

type TProps = TMenuAnimationOptions;

export const OxMenu = (props: TProps): JSX.Element => {
  const animationOptions: TMenuAnimationOptions = {
    show: props.show,
    animationTime: props.animationTime
  };

  return (
    <Styled.Container {...animationOptions}>
      <Styled.AnimatedBackground {...animationOptions}>
        <OxIcon name="ouronyx-icon" />
      </Styled.AnimatedBackground>
      <Styled.ContentWrapper>
        <OxMenuNavigation {...animationOptions} />
        <OxMenuAsset {...animationOptions} />
        <OxMenuNewsletter {...animationOptions} />
        <OxMenuSocial {...animationOptions} />
      </Styled.ContentWrapper>
    </Styled.Container>
  );
};
