import { EFontWeight, EFonts, ESizes } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxContainer } from "src/components/OxContainer";
import { OxIcon } from "src/components/OxIcon";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";
import { transparentize } from "polished";

export const Modal = styled.div<{ show: boolean }>(
  ({ show, theme }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${transparentize(0.25, theme.colors.basic.white)};
    z-index: 200;
    opacity: 0;
    visibility: hidden;
    transition: 500ms;
    ${show &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  `
);

export const ModalOverlay = styled.div`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  top: 50%;
  transform: translate(0, -50%);
  ${fluidSizing([
    {
      prop: "padding-top",
      values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
    },
    {
      prop: "padding-bottom",
      values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
    },
    { prop: "left", values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM] },
    { prop: "right", values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM] }
  ])}
`;

export const ModalContent = styled(OxContainer)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.primary.main};
    ${fluidSizing([
      {
        prop: "padding-top",
        values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
      },
      {
        prop: "padding-bottom",
        values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
      }
    ])}
  `
);

export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;

export const Logo = styled(OxIcon)(
  ({ theme }) => css`
    color: ${theme.colors.basic.white};
    ${fluidSizing([
      { prop: "width", values: [60, 60, 60] },
      { prop: "height", values: [80, 80, 80] }
    ])}
  `
);

export const PageTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.basic.white};
    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Normal,
      [30, 30, 30],
      [40, 40, 40]
    )}
    ${fluidSizing([{ prop: "margin-bottom", values: [30, null, null] }])}
  `
);

export const ContentTitle = styled.div(
  ({ theme }) => css`
  text-transform: uppercase;
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Bold,
    [11, 11, 11],
    [14, 14, 14]
  )}
  color: ${theme.colors.basic.white};
  ${fluidSizing([{ prop: "margin-bottom", values: [30, 30, 30] }])}
  `
);

export const ContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
    }
  `
);

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidSizing([{ prop: "margin-left", values: [null, 40, 40] }])}
`;

export const Close = styled.div(
  ({ theme }) => css`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [11, 11, 11],
    [14, 14, 14]
  )}
  color: ${theme.colors.basic.white};
  border-bottom: 1px solid ${theme.colors.basic.white};
  ${fluidSizing([
    { prop: "width", values: [25, 33, 33] },
    { prop: "padding-bottom", values: [1, 1, 1] }
  ])}
  cursor: pointer;
  `
);

export const CloseIcon = styled(OxIcon)(
  ({ theme }) => css`
  position: absolute;
  cursor: pointer;
  left: 97%;
  ${fluidSizing([
    { prop: "width", values: [16, 16, 16] },
    { prop: "height", values: [16, 16, 16] }
  ])}
  color: ${theme.colors.basic.white};
  `
);
