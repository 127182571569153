import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxContainer } from "src/components/OxContainer";
import { TMenuAnimationOptions } from "src/components/OxMenu";
import { createTextStyle } from "src/utils/createTextStyle";
import { showWithDelayAnimation } from "src/styles/animations";
import { toVw } from "src/utils";
import { fluidSizing } from "src/utils";

export const Navigation = styled(OxContainer)<TMenuAnimationOptions>(
  ({ theme, animationTime, show }) => css`
    grid-area: MenuNavigation;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${showWithDelayAnimation(show, animationTime * 0.4, animationTime * 0.5)};

    ${theme.breakpoints.only("xs")} {
      padding-bottom: ${toVw(50, "xs")};
    }

    ${theme.breakpoints.only("s")} {
      padding-top: ${toVw(20, "s")};
    }

    ${theme.breakpoints.up("m")} {
      justify-content: center;
    }
  `
);

export const NavItem = styled.li<
  { total: number; index: number } & TMenuAnimationOptions
>(
  ({ theme, animationTime, show, total, index }) => css`
    opacity: 0;
    transition-delay: ${animationTime}ms;
    transform: translateY(${(40 * index) / total - 70}px);

    a {
      transition: 300ms;
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [18, 18, 18],
        [36, 36, 36]
      )}

      &.active {
        font-weight: ${theme.fontWeights.semiBold} !important;
      }

      &:hover:not(.active) {
        color: ${theme.colors.basic.black};
      }
    }

    ${theme.breakpoints.up("m")} {
      text-align: center;
    }

    ${show &&
      css`
        transform: translateY(0px) !important;
        transition-delay: 300ms;
        opacity: 1;

        transition-duration: ${animationTime * (1 - (0.5 * index) / total)}ms;
      `}

    &:nth-child(4) {
      ${fluidSizing([{ prop: "margin-bottom", values: [40, 40, 40] }])}
    }
    &:nth-child(n + 5) a {
      ${createTextStyle(
        EFonts.Hatton,
        EFontWeight.Medium,
        [15, 15, 15],
        [36, 36, 36]
      )}
    }
  `
);

export const Iconogram = styled.div<TMenuAnimationOptions>(
  ({ theme, show, animationTime }) => css`
    position: absolute;
    z-index: -1;
    color: ${theme.colors.basic.ouronyxLogo};
    ${showWithDelayAnimation(show, animationTime * 0.8, animationTime * 0.3)}
    ${theme.breakpoints.only("xs")} {
      width: 75%;
      right: 5%;
      bottom: 0;
    }
    ${theme.breakpoints.only("s")} {
      width: 35%;
      height: auto;
      bottom: auto;
      top: 50%;
      right: 13%;
      transform: translateY(-50%);
    }
    ${theme.breakpoints.up("m")} {
      width: 84%;
      right: 8%;
      top: 0;
      transform: none;
    }
  `
);
