import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Modal = styled.div<{ show: boolean }>(
  ({ show }) => css`
    background-color: rgba(236, 226, 214, 0.84);
    display: grid;
    height: 100vh;
    opacity: 0;
    place-items: center;
    position: fixed;
    transition: 500ms;
    visibility: hidden;
    width: 100vw;
    z-index: 200;
    ${show &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  `
);

export const ModalOverlay = styled.div``;

export const Content = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.basic.white};
    box-shadow: 0 0 30px rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
    ${fluidSizing([
      { prop: "width", values: [390, 390, 390] },
      { prop: "padding", values: [28, 40, 40] }
    ])}
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary.main};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Bold,
      [12, 12, 12],
      [15, 15, 15]
    )}
    text-transform: uppercase;
    ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
  `
);

export const Title = styled.div`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.SemiBold,
    [27, 27, 27],
    [34, 34, 34]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const Message = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [14, 14, 14],
    [16, 16, 16]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const ConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  ${fluidSizing([{ prop: "gap", values: [15, 15, 15] }])}
`;
