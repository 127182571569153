import * as Styled from "./OxLink.styled";

import React, { useContext } from "react";
import { ThemedInputContext } from "src/context/ThemedInputContext";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const OxLink = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  state,
  subItem,
  hideOnMobile,
  target,
  withUnderlineAnimation,
  asButton,
  forceExternal,
  ...other
}: any): JSX.Element => {
  const themeContext = useContext(ThemedInputContext);

  if (asButton) {
    return (
      <Styled.Button
        $customTheme={themeContext}
        to={to}
        activeClassName={activeClassName || "active"}
        partiallyActive={partiallyActive}
        state={state}
        $withUnderlineAnimation={withUnderlineAnimation}
        {...other}
      >
        {children}
      </Styled.Button>
    );
  }

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const external = !/^\/(?!\/)/.test(to) || target || forceExternal;
  // Use Gatsby Link for internal links, and <a> for others

  if (!external) {
    return (
      <Styled.GatsbyLink
        $customTheme={themeContext}
        to={to}
        activeClassName={activeClassName || "active"}
        partiallyActive={partiallyActive}
        state={state}
        $withUnderlineAnimation={withUnderlineAnimation}
        {...other}
      >
        {children}
      </Styled.GatsbyLink>
    );
  }
  return (
    <Styled.RegularLink
      $customTheme={themeContext}
      href={to}
      target={target}
      $withUnderlineAnimation={withUnderlineAnimation}
      {...other}
    >
      {children}
    </Styled.RegularLink>
  );
};
