import { TAlertData } from "src/context/AlertContext";
import * as Sentry from "@sentry/gatsby";

export class OnyxError extends Error {
  error: TAlertData;

  constructor(error: TAlertData, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, OnyxError);
    }

    // Custom debugging information
    this.error = error;
    if (!error.sentryIgnore) {
      const message = this.message
        ? this.message
        : `${error.header}: ${error.title ?? error.message}`;

      const trackableError = new OnyxError(
        { ...error, sentryIgnore: true },
        message
      );
      Sentry.captureException(trackableError, { extra: trackableError.error });
    }
  }
}
