import styled, { css } from "styled-components";

import { TMenuAnimationOptions } from "src/components/OxMenu";

export const Container = styled.div(
  ({ theme }) => css`
    grid-area: MenuAsset;
    position: relative;
    ${theme.breakpoints.down("s")} {
      display: none;
    }
  `
);

export const Background = styled.div<TMenuAnimationOptions>(
  ({ theme, show, animationTime }) => css`
    width: 100%;
    ${theme.breakpoints.between("xs", "s")} {
      height: 0%;
      opacity: 0;
      transition-duration: ${animationTime * 0.5}ms;
      transition-delay: ${animationTime * 0.2}ms;
      transition-timing-function: cubic-bezier(0.89, 0.1, 0.26, 0.94);
    }
    ${theme.breakpoints.up("m")} {
      opacity: 1;
      height: 100%;
      overflow: hidden;
      position: relative;
      transform: translateY(-100%);
      transition-property: height transform;
      transition-duration: ${animationTime}ms;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.89, 0.1, 0.26, 0.94);
    }
    ${show &&
      css`
        ${theme.breakpoints.between("xs", "s")} {
          height: 100%;
          opacity: 1;
          transition-duration: ${animationTime * 0.5}ms;
          transition-delay: ${animationTime * 0.5}ms;
          transition-timing-function: ease;
        }
        ${theme.breakpoints.up("m")} {
          transition-duration: ${animationTime}ms;
          transition-delay: 0s;
          transform: translateY(0);
          transition-timing-function: cubic-bezier(0.89, 0.1, 0.26, 0.94);
        }
      `}
  `
);

export const ImageWrapper = styled.div<TMenuAnimationOptions>(
  ({ theme, show, animationTime }) => css`
    height: 100%;
    width: 100%;
    object-fit: cover;
    .gatsby-image-wrapper {
      height: 100%;
    }
    ${theme.breakpoints.up("m")} {
      transform: translateY(50%);
      transition-duration: ${animationTime}ms;
      transition-timing-function: cubic-bezier(0.89, 0.1, 0.26, 0.94);
      ${show &&
        css`
          transform: translateY(0%);
        `}
    }
  `
);
