import React, {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import * as Styled from "./OxRootContainer.styled";
import { graphql, useStaticQuery } from "gatsby";

import { OxHygyene } from "src/components/OxHygene";
import { useQueryParam, StringParam } from "use-query-params";
import { WindowLocation } from "@reach/router";
import { TClinic, TConfig, TPractitioner } from "src/services/api/api.types";
import {
  bookingFlowReducer,
  EBookingFlowAction,
  initialBookingFlowState,
} from "../OxBooking/OxBookingFlowReducer";
import { clinikoGetClinicList, clinikoGetDoctors } from "src/services/api";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { BookingFlowContext } from "src/context/BookingFlowContext";
import { ETimes } from "src/config/enums";
import { LayoutContext, TLayoutContext } from "src/context/LayoutContext";
import { OxFooter } from "src/components/OxFooter";
import { OxLoader } from "src/components/OxLoader";
import { OxStickyHeader } from "src/components/OxStickyHeader";
import { ParallaxProvider } from "react-scroll-parallax";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";
import throttle from "lodash/throttle";
import { toPx } from "src/utils";
import { useBreakpoints } from "src/hooks";
import { AlertContext, TAlertData } from "src/context/AlertContext";
import { OxAlert } from "src/components/OxAlert/OxAlert";
import { fireEvent } from "src/helpers/TagManager";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { getCountry } from "src/services/api/geoip";
import { CONFIG_PATH, getConfig } from "src/services/api/config";

type TProps = {
  children: ReactNode;
  location?: WindowLocation;
};

type TCustomGQLNode<P> = {
  data: P;
};

export const OxRootContainer = ({
  location,
  children,
}: TProps): JSX.Element => {
  const prefetchedData = useStaticQuery(query);
  const isDataPrefetched =
    prefetchedData?.doctors?.nodes.length &&
    prefetchedData?.locations?.nodes?.length &&
    prefetchedData?.config?.nodes?.length;

  const { validateResponse } = useValidateResponse();
  const device = useBreakpoints();
  const [bookingContainerRef, setBookingContainerRef] =
    useState<RefObject<HTMLDivElement>>();

  /*
   * This is necessary because injecting doctors/locations into the gatsby graphql datalayer requires
   * giving them a unique ID, but we use the ID throughout the site, so we have to move it to a dummy
   * property, then move it back here...
   */
  const [doctors, setDoctors] = useState<TPractitioner[]>(() =>
    (prefetchedData?.doctors?.nodes ?? []).map(
      ({ data }: TCustomGQLNode<TPractitioner>) => data
    )
  );
  const [locations, setLocations] = useState<TClinic[]>(() =>
    (prefetchedData?.locations?.nodes ?? []).map(
      ({ data }: TCustomGQLNode<TClinic>) => data
    )
  );
  const [config, setConfig] = useState<TConfig[]>(() =>
    (prefetchedData?.config?.nodes ?? []).map(
      ({ data }: TCustomGQLNode<TConfig>) => data
    )
  );
  const [geoIpCountry, setCountry] = useState<string | null | undefined>("GB");
  const [debugCountry] = useQueryParam("geoip_debug", StringParam);
  const [showLoader, setShowLoader] = useState(!isDataPrefetched);
  const [animateLoader, setAnimateLoader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<TAlertData | null>(null);
  const [bookingFlowReducerState, bookingFlowReducerDispatch] = useReducer(
    bookingFlowReducer,
    initialBookingFlowState
  );

  const [modalId, setModalId] = useState<string | null>(null);

  const setMenuIsOpenCallback = useCallback(
    throttle(setMenuIsOpen, ETimes.MenuAnimationTime),
    []
  );

  const layoutContextValue: TLayoutContext = {
    menuIsOpen,
    websiteLoaderActive: showLoader,
    setMenuIsOpen: setMenuIsOpenCallback,
    modalId: modalId,
    setModalId: setModalId,
  };

  const showAlert = (data: TAlertData): void => setAlert(data);
  const hideAlert = (): void => setAlert(null);

  const errorAlertContextValue = {
    alert,
    showAlert,
    hideAlert,
  };

  const defaultScopeId = 0;

  const getConfigValue = useCallback(
    (path: string, scopeId = defaultScopeId) => {
      return useMemo(() => {
        const configValues = config.filter(
          (configItem) => configItem.path === path
        );

        // If not set per clinic ID, get the default
        return (
          configValues.find((item) => item.clinicId === scopeId) ??
          configValues.find((item) => item.clinicId === defaultScopeId)
        )?.value;
      }, [path, scopeId, config]);
    },
    [config]
  );

  const websiteDataContextValue = {
    doctors,
    locations,
    geoIpCountry,
    getConfigValue,
  };

  const setBookingContainerReference = (
    reference: RefObject<HTMLDivElement>
  ): void => {
    setBookingContainerRef(reference);
  };

  const scrollToBookingComponentTop = (): void => {
    if (!bookingContainerRef?.current?.offsetTop) return;

    window.scrollTo({
      top: bookingContainerRef?.current?.offsetTop - toPx(65, device),
      behavior: "smooth",
    });
  };

  const fetchWebsiteData = async (): Promise<void> => {
    const doctorsData = await clinikoGetDoctors();
    const locationsData = await clinikoGetClinicList();
    const countryData = await getCountry();
    const configData = await getConfig();

    try {
      validateResponse(doctorsData);
      setDoctors(doctorsData ?? []);
    } catch (e) {
      console.error(e);
    }

    try {
      validateResponse(locationsData);
      setLocations(locationsData ?? []);
    } catch (e) {
      console.error(e);
    }

    if (countryData && !debugCountry) {
      try {
        validateResponse(countryData);
        setCountry(countryData?.country);
      } catch (e) {
        console.error(e);
      }
    }

    try {
      validateResponse(configData);
      setConfig(configData ?? []);
    } catch (e) {
      console.error(e);
    }
  };

  const bookingFlowContextValue = {
    reset: (): void => {
      bookingFlowReducerDispatch({
        type: EBookingFlowAction.SetBookingFlowState,
        payload: initialBookingFlowState,
      });
    },
    paymentDisabled:
      getConfigValue(
        CONFIG_PATH.CHECKOUT_PAYMENT_REQUIRED,
        bookingFlowReducerState.clinic?.id
      ) !== "1",
    activeStepId: activeStep,
    state: bookingFlowReducerState,
    setBookingContainerReference,
    scrollToComponentTop: scrollToBookingComponentTop,
    dispatch: bookingFlowReducerDispatch,
    goToNextStep: (): void => {
      setActiveStep((prev) => prev + 1);
      scrollToBookingComponentTop();
    },
    goToPrevStep: (): void => {
      setActiveStep((prev) => prev - 1);
      scrollToBookingComponentTop();
    },
    goToStep: (stepIndex: number): void => {
      setActiveStep(stepIndex);
      scrollToBookingComponentTop();
    },
  };

  const onWebsiteResize = (): void => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const contentOnlyMode =
    ["/panel/staff/dashboard/psychological-attributes"].filter(
      (path) => location && location?.pathname.indexOf(path) !== -1
    ).length >= 1;

  const minimalHeaderAndFooter =
    [
      "/panel/staff/dashboard/treatment-plans",
      "/panel/staff/dashboard/treatment-notes",
      "/panel/staff/dashboard/refreshments",
    ].filter((path) => location && location?.pathname.indexOf(path) !== -1)
      .length >= 1;

  useEffect(() => {
    const asyncAction = async (): Promise<void> => {
      await fetchWebsiteData();
      setShowLoader(false);
    };

    !isDataPrefetched && setAnimateLoader(true);
    onWebsiteResize();
    asyncAction();

    debugCountry && setCountry(debugCountry);
    // window.addEventListener("resize", onWebsiteResize);
    // return (): void => window.removeEventListener("resize", onWebsiteResize);
  }, []);

  useEffect(() => {
    let props = {};

    switch (activeStep) {
      default:
      case 0:
        props = {
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
              },
            },
          },
        };
        break;
      case 1:
        //Book Now
        props = {
          ecommerce: {
            checkout: {
              actionField: {
                step: 2,
              },
            },
          },
        };
        break;
      case 2:
        //Where would you like your consultation
        props = {
          ecommerce: {
            checkout: {
              actionField: {
                step: 3,
                option: bookingFlowReducerState.userLoggedIn
                  ? "Logged In"
                  : "Guest",
              },
            },
          },
        };
        break;
      case 3: {
        //Do you know which Doctor you would like to book with?
        let eventLocation = "";
        locations.forEach(function (element) {
          if (element.id == bookingFlowReducerState.consultationLocationId) {
            eventLocation = element.displayName ?? "";
          }
        });
        props = {
          ecommerce: {
            checkout: {
              actionField: {
                step: 4,
                option: eventLocation,
              },
            },
          },
        };
        break;
      }
      case 4:
        //Now, select your Doctor to view available appointments
        props = {
          ecommerce: {
            checkout: {
              actionField: {
                step: 5,
                //'option': bookingFlowReducerState.chooseDoctorForMe ? 'Choose Doctor for me' : 'Has Favourite Doctor'
              },
            },
          },
        };
        break;
      case 5:
        //Select Date
        props = {
          ecommerce: {
            checkout: {
              actionField: {
                step: 6,
                option: bookingFlowReducerState.chooseDoctorForMe
                  ? "Choose Doctor for me"
                  : "Has Favourite Doctor",
              },
            },
          },
        };
        break;
      case 6:
        //Thank You
        props = {
          ecommerce: {
            checkout: {
              actionField: {
                step: 7,
              },
            },
          },
        };
        break;
    }

    if (
      props &&
      Object.keys(props).length !== 0 &&
      props.constructor === Object
    ) {
      fireEvent({ event: "checkoutStep", props });
    }
  }, [activeStep, bookingFlowReducerState.currentStage]);

  useEffect(() => {
    if (menuIsOpen)
      disableBodyScroll(document.body, { reserveScrollBarGap: true });
    else enableBodyScroll(document.body);
  }, [menuIsOpen]);

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      <WebsiteDataContext.Provider value={websiteDataContextValue}>
        <BookingFlowContext.Provider value={bookingFlowContextValue}>
          <AlertContext.Provider value={errorAlertContextValue}>
            <ParallaxProvider>
              <OxLoader animate={animateLoader} show={showLoader} />
              <Styled.Layout data-geo-ip={geoIpCountry}>
                {!contentOnlyMode && (
                  <OxStickyHeader
                    minimalHeaderAndFooter={minimalHeaderAndFooter}
                  />
                )}
                {children}
                <OxAlert />
                {!contentOnlyMode && (
                  <>
                    <OxHygyene />
                    <OxFooter minimalHeaderAndFooter={minimalHeaderAndFooter} />
                  </>
                )}
              </Styled.Layout>
            </ParallaxProvider>
          </AlertContext.Provider>
        </BookingFlowContext.Provider>
      </WebsiteDataContext.Provider>
    </LayoutContext.Provider>
  );
};

const query = graphql`
  query DataQuery {
    doctors: allMiddlewareDoctors {
      nodes {
        data {
          clinicId
          description
          designation
          firstName
          id
          label
          lastName
          practitionerId
          showInOnlineBookings
          title
        }
      }
    }

    locations: allMiddlewareLocations {
      nodes {
        data {
          id
          businessId
          businessName
          displayName
          businessRegistrationName
          businessRegistrationValue
          address1
          address2
          city
          state
          postCode
          countryCode
          appointmentRemindersEnabled
          showInOnlineBookings
          contactInformation
          label
          emailReplyTo
          websiteAddress
          accountInstance {
            clinikoUrl
          }
          currency
          preTreatmentAppointmentTypeId
          preTreatmentPractitionerId
          active
          virtualAppointmentTypeId
          virtualPractitionerId
          defaultAppointmentTypeId
          openDays
          utc_offset
          timezone
        }
      }
    }

    config: allMiddlewareConfigs {
      nodes {
        data {
          clinicId
          path
          scope
          value
        }
      }
    }
  }
`;
