import { EApiEndpoint } from "src/config/enums";
import { apiGetCall } from "src/services/api/api.core";
import { applyValues } from "src/utils/applyValues";
import { TConfig } from "src/services/api/api.types";

export enum CONFIG_PATH {
  CHECKOUT_PAYMENT_REQUIRED = "checkout.payment_capture_required",
  STRIPE_PUBLISHABLE_KEY = "stripe.publishable_key",
  APPOINTMENT_CANCELLATION_GRACE_PERIOD = "appointment_cancellation.grace_period",
  APPOINTMENT_CANCELLATION_FEE_OVERRIDE = "appointment_cancellation.fee_override"
}

export const getConfig = async (): Promise<TConfig[] | null> => {
  return await apiGetCall(EApiEndpoint.Config);
};

export const getScopedConfig = async (
  path: CONFIG_PATH,
  clinicId?: number
): Promise<TConfig | null> => {
  return await apiGetCall(
    applyValues(EApiEndpoint.ClinicConfig, { path, clinicId: clinicId ?? 0 })
  );
};
