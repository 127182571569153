import { TClinic, TPractitioner } from "src/services/api/api.types";

import React from "react";

export type TWebsiteDataContext = {
  doctors: TPractitioner[];
  locations: TClinic[];
  geoIpCountry?: string | null;

  getConfigValue: (path: string, clinicId?: number) => string | undefined;
};

export const WebsiteDataContext = React.createContext(
  {} as TWebsiteDataContext
);
