import { createTextStyle } from "src/utils/createTextStyle";
import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";

export const Tagline = styled.div(
  ({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.colors.primary.main};
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Bold,
      [10, 10, 10],
      [14, 14, 14]
    )}
    ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
  `
);
