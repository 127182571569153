import React from "react";

export enum EAlertVariant {
  Confirm = "CONFIRM",
  Error = "ERROR",
  Success = "SUCCESS",
}

export type TAlertData = {
  type: EAlertVariant;
  header?: string;
  title?: string;
  message?:
    | string
    | React.ReactFragment
    | JSX.Element
    | (string | React.ReactFragment | JSX.Element)[];
  buttonId?: string;
  /** additionalInfo is not displayed on the FE, but is used to provide more data to Sentry */
  additionalInfo?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  noCancel?: boolean;
  onlyConfirm?: boolean;
  confirmCTA?: string;
  hideAfterConfirm?: boolean;
  sentryIgnore?: boolean;
};

export type TAlertContext = {
  alert: TAlertData | null;
  showAlert: (data: TAlertData) => void;
  hideAlert: () => void;
};

export const AlertContext = React.createContext({} as TAlertContext);
