import styled, { css } from "styled-components";

import { fluidSizing } from "src/utils";

export const Container = styled.div<{ show: boolean }>(
  ({ theme, show }) => css`
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${theme.colors.secondary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition-duration: 0.4s;
    ${show &&
      css`
        visibility: visible;
        opacity: 1;
      `}
  `
);

export const LogoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  ${fluidSizing([
    { prop: "width", values: [65, 65, 65] },
    { prop: "height", values: [87, 87, 87] }
  ])}
`;

export const LogoStroke = styled.div<{ animate: boolean }>(
  ({ animate }) => css`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transition-duration: 1s;
    ${animate &&
      css`
        height: 0%;
      `}
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  `
);

export const LogoFilled = styled.div<{ animate: boolean }>(
  ({ animate }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0%;
    width: 100%;
    overflow: hidden;
    transition-duration: 1s;
    ${animate &&
      css`
        height: 100%;
      `}
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  `
);
