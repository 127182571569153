import { FlattenSimpleInterpolation, css, keyframes } from "styled-components";
import {EColors} from "src/config/enums";

export const showWithDelayAnimation = (
  show: boolean,
  delay = 500,
  duration = 500
): FlattenSimpleInterpolation => css`
  opacity: 0;
  transition-timing-function: ease-in-out;
  transition-property: opacity;
  transition-delay: 0ms;
  transition-duration: ${duration}ms;
  ${show &&
    css`
      opacity: 1;
      transition-delay: ${delay}ms;
    `}
`;

export const waterfallAnimation = (
  show: boolean,
  numberOfItems: number,
  animationTime: number
): FlattenSimpleInterpolation => {
  const arr = new Array(numberOfItems).fill(null);
  return css`
    & > * {
      opacity: 0;
      transition-delay: 1s;
      transition-timing-function: ease-in-out;
    }
    ${show &&
      css`
        ${arr.map(
          (_, index) => css`
            & > *:nth-child(${index + 1}) {
              opacity: 1;
              transition-property: opacity;
              transition-delay: ${animationTime * 0.5}ms;
              transition-duration: ${Math.round(500 * (index + 1))}ms;
            }
          `
        )}
      `}
  `;
};

export const moveLeftBack = keyframes`
  0% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(50%);
  }
  0% {
    transform: translateX(0px);
  }
`;

export const hoverUnderlineAnimation = ({ theme }, backgroundColor?: EColors) => css`
  position: relative;
  &:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }
  &:after {
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    background:${backgroundColor ? backgroundColor :theme.colors.primary.main} ;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-out;
  }
`;
