import * as Styled from "./OxMenuAsset.styled";

import { graphql, useStaticQuery } from "gatsby";

import { OxImage } from "../OxImage";
import React from "react";
import { TMenuAnimationOptions } from "src/components/OxMenu";

type TProps = TMenuAnimationOptions;

export const OxMenuAsset = (props: TProps): JSX.Element => {
  const data = useStaticQuery(
    graphql`
      query {
        menuDesktopImage: file(relativePath: { eq: "Menu-Image-Desktop.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        menuTabletImage: file(relativePath: { eq: "Menu-Image-Tablet.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const fluidImages = [
    {
      ...data.menuDesktopImage.childImageSharp.gatsbyImageData,
      media: `(min-width: 1024px)`,
    },
    {
      ...data.menuTabletImage.childImageSharp.gatsbyImageData,
      media: `(max-width: 1023px)`,
    },
  ];

  return (
    <Styled.Container>
      <Styled.Background show={props.show} animationTime={props.animationTime}>
        <Styled.ImageWrapper
          show={props.show}
          animationTime={props.animationTime}
        >
          <OxImage fluidImages={fluidImages} alt="Ouronyx Menu" />
        </Styled.ImageWrapper>
      </Styled.Background>
    </Styled.Container>
  );
};
