import * as Styled from "./OxStickyHeader.styled";

import React, { useContext } from "react";

import { ERoutes, ETimes } from "src/config/enums";
import { LayoutContext } from "src/context/LayoutContext";
import { OxIcon } from "src/components/OxIcon";
import { OxMenu } from "src/components/OxMenu";
import { navigate } from "@reach/router";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { useBreakpoints } from "src/hooks";
import { fireEvent } from "src/helpers/TagManager";
import { OxHeaderNavLinks } from "src/components/OxStickyHeader/components/OxHeaderNavLinks";

type TProps = {
  minimalHeaderAndFooter?: boolean;
};

export function OxStickyHeader({
  minimalHeaderAndFooter,
}: TProps): JSX.Element {
  const device = useBreakpoints();
  const { menuIsOpen, setMenuIsOpen } = useContext(LayoutContext);

  const toggleMenu = (): void => {
    setMenuIsOpen(!menuIsOpen);
  };

  const onConsultationButtonClick = (): void => {
    fireEvent({ event: "headerBookConsultationButtonScrollClicked" });
    navigate(ERoutes.Appointment, {
      state: {
        id: "#booking",
      },
    });
  };

  const onContactButtonClick = (): void => {
    navigate(ERoutes.Contact, {
      state: {
        id: "#contact",
      },
    });
  };

  return (
    <Styled.Header>
      <OxMenu show={menuIsOpen} animationTime={ETimes.MenuAnimationTime} />
      <Styled.Logo to="/">
        <OxIcon name="ouronyx" />
      </Styled.Logo>
      {!minimalHeaderAndFooter && (
        <>
          <OxHeaderNavLinks />
          {!device.xs && <Styled.Social compressed justSocial small />}
          <OxThemedInput theme={EInputTheme.Gold}>
            <Styled.ConsultationButton
              type="button"
              onClick={onConsultationButtonClick}
            >
              Book
            </Styled.ConsultationButton>
          </OxThemedInput>
          <OxThemedInput theme={EInputTheme.Gold}>
            <Styled.ContactButton type="button" onClick={onContactButtonClick}>
              Contact
            </Styled.ContactButton>
          </OxThemedInput>
          <Styled.Profile to={ERoutes.PanelCustomerLogin}>
            <OxIcon name="profile" />
          </Styled.Profile>
          <Styled.NavButton
            onClick={toggleMenu}
            isOpen={menuIsOpen}
            aria-label="Toggle Menu"
          >
            <span></span>
            <span></span>
            <span></span>
            {/* <Styled.NavIndicator isOpen={menuIsOpen} /> */}
          </Styled.NavButton>
        </>
      )}
    </Styled.Header>
  );
}
