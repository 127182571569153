import * as Styled from "./OxFooter.styled";

import React, { useContext } from "react";

import { ERoutes } from "src/config/enums";
import { LayoutContext } from "src/context/LayoutContext";
import { OxIcon } from "src/components/OxIcon";
import { OxLink } from "src/components/OxLink";
import { useBreakpoints } from "src/hooks";
import { LocationsData } from "src/constants/locations";
import { graphql, useStaticQuery } from "gatsby";
import { Hygyene, Page } from "src/services/cms/cms.types";
import { generateImgSizes } from "src/utils";

type TProps = {
  minimalHeaderAndFooter?: boolean;
};

type TLink = {
  title: string;
  to?: ERoutes;
  onClick?: () => void;
  showOnMobile?: boolean;
};

export const OxFooter = ({ minimalHeaderAndFooter }: TProps): JSX.Element => {
  const layoutContext = useContext(LayoutContext);
  const device = useBreakpoints();

  const { pages, hygyene, CQC, ISCAS, UAEMoHP } = useStaticQuery(
    graphql`
      query {
        pages: allSanityPage(
          sort: { fields: [order], order: ASC }
          filter: {
            slug: { current: { ne: null } }
            enabled: { eq: true }
            show_in_footer: { eq: true }
          }
        ) {
          nodes {
            _id
            name
            slug {
              current
            }
          }
        }
        hygyene: allSanityHygyene(filter: { displayInFooter: { eq: true } }) {
          nodes {
            contentTitle
            identifier
            pageTitle
            points {
              description
              title
            }
          }
        }
        CQC: file(relativePath: { eq: "footer/CQC.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ISCAS: file(relativePath: { eq: "footer/ISCAS.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        UAEMoHP: file(relativePath: { eq: "footer/UAEMoHP.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const linksPrimary: TLink[] = [
    ...(pages?.nodes?.map(({ name, slug }: Page) => ({
      title: name,
      to: slug?.current,
    })) ?? []),
    {
      to: ERoutes.PanelCustomerLogin,
      title: "My Account",
      showOnMobile: true,
    },
    {
      to: ERoutes.Appointment,
      title: "Book Now",
    },
    {
      to: ERoutes.Contact,
      title: "Contact",
      showOnMobile: true,
    },
  ];

  const CDCWidths = [100, 100, 100];
  const CEDRWidths = [101, 101, 101];
  const MOHAPWidths = [111, 111, 111];

  return (
    <Styled.Container>
      <Styled.Icon>
        <OxIcon name="ouronyx-icon" />
      </Styled.Icon>
      {!minimalHeaderAndFooter && (
        <>
          <Styled.Locations>
            <h4>Find Us</h4>
            {Object.entries(LocationsData).map(([key, location]) => (
              <Styled.Location key={key}>
                <Styled.LocationName>{location.name}</Styled.LocationName>
                <Styled.LocationAddress>
                  {location.address?.streetLines &&
                    location.address.streetLines.map((line, key) => (
                      <p key={key}>{line}</p>
                    ))}
                  {(location.address?.city || location.address?.postcode) && (
                    <p>
                      {location.address.city}
                      {location.address?.city &&
                        location.address?.postcode &&
                        ", "}
                      {location.address.postcode}
                    </p>
                  )}
                  {location.email && (
                    <Styled.LocationLink
                      withUnderlineAnimation
                      to={"mailto:" + location.email}
                    >
                      {location.email}
                    </Styled.LocationLink>
                  )}
                  {location.phone && (
                    <Styled.LocationLink
                      withUnderlineAnimation
                      to={"tel:" + location.phone}
                    >
                      {location.phoneFormatted ?? location.phone}
                    </Styled.LocationLink>
                  )}
                </Styled.LocationAddress>
                {location.note && <p>{location.note}</p>}
              </Styled.Location>
            ))}
          </Styled.Locations>
          <Styled.Navigation>
            <ul>
              {linksPrimary
                .filter((link) => !device.xs || link.showOnMobile)
                .map((link, key) => (
                  <li key={key}>
                    <OxLink
                      withUnderlineAnimation
                      to={link.to}
                      onClick={link.onClick}
                    >
                      {link.title}
                    </OxLink>
                  </li>
                ))}
            </ul>
            <ul>
              {hygyene.nodes.map((node: Hygyene, index: number) => (
                <li key={index}>
                  <OxLink
                    asButton
                    title={node.pageTitle}
                    withUnderlineAnimation
                    onClick={(): void =>
                      layoutContext.setModalId(node?.identifier ?? "")
                    }
                  >
                    {node.pageTitle}
                  </OxLink>
                </li>
              ))}
            </ul>
          </Styled.Navigation>
          <Styled.Newsletter />
        </>
      )}
      <Styled.Regulated>
        <Styled.RegulatedSection>
          <Styled.RegulatedItemLeft>
            <Styled.ImgWrapper
              widths={CDCWidths}
              target="_blank"
              rel="noopener"
              title="CQC"
              to="https://www.cqc.org.uk/location/1-10797130515"
            >
              <Styled.Img
                widths={CDCWidths}
                alt="CQC"
                image={CQC.childImageSharp.gatsbyImageData}
                sizes={generateImgSizes({ values: CDCWidths })}
              />
            </Styled.ImgWrapper>
          </Styled.RegulatedItemLeft>

          <Styled.RegulatedItemRight>
            <Styled.ImgWrapper
              widths={MOHAPWidths}
              target="_blank"
              rel="noopener"
              title="MOHAP"
              to="https://mohap.gov.ae/"
            >
              <Styled.Img
                widths={MOHAPWidths}
                alt="Mohap"
                image={UAEMoHP.childImageSharp.gatsbyImageData}
                sizes={generateImgSizes({ values: MOHAPWidths })}
              />
            </Styled.ImgWrapper>
          </Styled.RegulatedItemRight>

          <Styled.RegulatedItemLeft>
            <Styled.ImgWrapper
              widths={CEDRWidths}
              target="_blank"
              rel="noopener"
              title="CEDR"
              to="https://iscas.cedr.com/"
            >
              <Styled.Img
                widths={CEDRWidths}
                alt="ISCAS"
                image={ISCAS.childImageSharp.gatsbyImageData}
                sizes={generateImgSizes({ values: CEDRWidths })}
              />
            </Styled.ImgWrapper>
          </Styled.RegulatedItemLeft>
          <Styled.RegulatedItemRight>
            <Styled.RegulatedText>MOH NO: A9B6RHD8-180424</Styled.RegulatedText>
          </Styled.RegulatedItemRight>
        </Styled.RegulatedSection>
      </Styled.Regulated>
      <Styled.Copyrights>
        © Ouronyx {new Date().getFullYear()} - All Rights Reserved
      </Styled.Copyrights>
    </Styled.Container>
  );
};
