import {
  showWithDelayAnimation,
  waterfallAnimation,
} from "src/styles/animations";
import styled, { css } from "styled-components";

import { OxContainer } from "src/components/OxContainer";
import { TMenuAnimationOptions } from "src/components/OxMenu";
import { fluidSizing } from "src/utils";

export const Container = styled(OxContainer)<TMenuAnimationOptions>(
  ({ theme, show, animationTime }) => css`
    grid-area: MenuSocial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > div {
      ${waterfallAnimation(show, 3, animationTime)}
      *:first-child {
        text-transform: uppercase;
      }
    }

    svg {
      ${fluidSizing([{ prop: "height", values: [18, 18, 18] }])}
    }

    ${showWithDelayAnimation(show, animationTime * 0.5, animationTime * 0.5)}

    ${theme.breakpoints.up("s")} {
      align-items: flex-end;
      padding-left: 0;
    }

    ${fluidSizing([
      { prop: "margin-bottom", values: [22, null, null, null, null] },
    ])}
  `
);
