export type TLocationAddress = {
  streetLines: string[];
  city: string;
  postcode?: string;
};

export type TLocationDataItem = {
  id: number;
  name: string;
  address?: TLocationAddress;
  phone?: string;
  phoneFormatted?: string;
  email: string;
  note?: string;
  link?: string;
  linkTitle?: string;
};

export type TLocationData = {
  [key: string]: TLocationDataItem;
};

export const LocationsData: TLocationData = {
  london: {
    id: 1,
    name: "London",
    address: {
      streetLines: ["20 St. James's Street"],
      city: "London",
      postcode: "SW1A 1ES",
    },
    phone: "02045421697",
    phoneFormatted: "+44 (0)204 542 1697",
    email: "london@ouronyx.com",
    linkTitle: "Book",
  },
  dubai: {
    id: 2,
    name: "Dubai",
    address: {
      streetLines: ["The Opus Tower - Unit C201"],
      city: "Al A'amal Street, Dubai",
    },
    email: "dubai@ouronyx.com",
    phone: "+97144562024",
    phoneFormatted: "+971 4 456 2024",
  },
};
