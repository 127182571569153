import { useContext } from "react";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { isNumeric } from "src/utils/isNumeric";
import { OnyxError } from "src/helpers/OnyxError";
import { useStore } from "react-redux";
import { userRoleIsAdmin } from "src/helpers/user";
import { AxiosError } from "axios";

type TReturn = { validateResponse: (data: any) => any };

const sentryIgnoreChecks = (data: Record<string, unknown>) =>
  data.name === "NetworkError" ||
  [AxiosError.ECONNABORTED, AxiosError.ETIMEDOUT].includes(data.code);

export const useValidateResponse = (): TReturn => {
  const { showAlert } = useContext(AlertContext);
  let store: any;
  try {
    store = useStore();
  } catch (e) {
    // Not in a store context
  }

  const validateResponse = (data: any): any => {
    const { user } = store?.getState() ?? { user: {} };

    const statusCode = data?.status || data?.statusCode;
    if (
      (statusCode &&
        isNumeric(statusCode) &&
        Math.floor(statusCode / 100) !== 2) ||
      data.error
    ) {
      const error = {
        type: EAlertVariant.Error,
        header: "API error",
        title: "Cliniko API error",
        message: data.title ?? data.error ?? data.asString,
        sentryIgnore: sentryIgnoreChecks(data), //Don't bother tracking network errors
      };

      if (
        !!showAlert &&
        statusCode === 429 &&
        user?.roles &&
        userRoleIsAdmin(user.roles)
      ) {
        showAlert(error);
      }

      throw new OnyxError(error);
    }
    return data;
  };

  return { validateResponse };
};
