import React, { useState } from "react";
import * as Styled from "./OxHeaderNavLink.styled";

type TOxHeaderNavLink = {
  to: string;
  title: string;
};

export const OxHeaderNavLink = ({
  title,
  to,
}: TOxHeaderNavLink): JSX.Element => {
  const [mouseEntered, setMouseEntered] = useState<boolean>(false);

  return (
    <Styled.Link
      withUnderlineAnimation
      forceExternal={!mouseEntered}
      onMouseEnter={() => setMouseEntered(true)}
      to={to}
    >
      {title}
    </Styled.Link>
  );
};
