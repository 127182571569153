import styled, { css } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import GatsbyBackgroundImage from "gatsby-background-image";

const showWithMediaQuery = ({
  $media,
  $artDirected,
}: {
  $media: string;
  $artDirected: boolean;
}) => css`
  ${$artDirected &&
  css`
    display: none;

    @media ${$media} {
      display: block;
    }
  `}
`;

export const Img = styled(GatsbyImage)<{
  $media: string;
  $artDirected: boolean;
}>(({ $media, $artDirected }) => showWithMediaQuery({ $media, $artDirected }));

export const BackgroundImage = styled(GatsbyBackgroundImage)<{
  $media: string;
  $artDirected: boolean;
}>(({ $media, $artDirected }) => showWithMediaQuery({ $media, $artDirected }));

export const BgImage = styled(BackgroundImage)<{ image: string }>(
  ({ image }) => css`
    background-image: url(${image});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  `
);
