import * as Styled from "./OxGetInTouch.styled";

import {
  SOCIAL_FACEBOOK,
  SOCIAL_INSTAGRAM,
  SOCIAL_YOUTUBE,
  SOCIAL_LINKEDIN
} from "src/config/constants";

import React from "react";

type TProps = {
  compressed?: boolean;
  justSocial?: boolean;
  small?: boolean;
};

export const OxGetInTouch = (props: SCProps<"div", TProps>): JSX.Element => {
  return (
    <Styled.Container {...props}>
      {!props.justSocial && (
        <>
          <Styled.Tag>Social</Styled.Tag>
          <Styled.Title>Follow Us</Styled.Title>
        </>
      )}
      <Styled.List>
        <li>
          <Styled.IconLink to={SOCIAL_INSTAGRAM} target="_blank" rel="noopener">
            <Styled.Icon name="instagram" small={props.small} />
          </Styled.IconLink>
        </li>
        <li>
          <Styled.IconLink to={SOCIAL_YOUTUBE} target="_blank" rel="noopener">
            <Styled.Icon name="youtube" small={props.small} />
          </Styled.IconLink>
        </li>
        <li>
          <Styled.IconLink to={SOCIAL_LINKEDIN} target="_blank" rel="noopener">
            <Styled.Icon name="linkedin" small={props.small} />
          </Styled.IconLink>
        </li>
        <li>
          <Styled.IconLink to={SOCIAL_FACEBOOK} target="_blank" rel="noopener">
            <Styled.Icon name="facebook" small={props.small} />
          </Styled.IconLink>
        </li>
      </Styled.List>
    </Styled.Container>
  );
};
