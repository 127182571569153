import * as Styled from "./OxInput.styled";

import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ThemedInputContext } from "src/context/ThemedInputContext";

export enum EOxInputType {
  Date = "date",
  Email = "email",
  Month = "month",
  Number = "number",
  Password = "password",
  Search = "search",
  Tel = "tel",
  Text = "text",
  Time = "time",
  Url = "url",
  Week = "week",
  Hidden = "hidden",
}

type TProps = {
  type: EOxInputType;
  suppressCustomValidationMessage?: boolean;
  initialValue?: string | number;
  role?: string;
  isInvalid?: boolean;
  transformValue?: (initialValue: string) => string;
  onInit: (el: HTMLInputElement) => void;
};

export const OxInput = React.forwardRef<
  HTMLInputElement,
  SCProps<"input", TProps>
>(
  (
    {
      children,
      className,
      type,
      placeholder,
      name,
      role,
      onBlur,
      initialValue,
      onChange,
      transformValue,
      isInvalid,
      suppressCustomValidationMessage,
      onInit,
      ...props
    }: SCProps<"input", TProps>,
    ref
  ): JSX.Element => {
    const [value, setValue] = useState(initialValue || "");
    const themeContext = useContext(ThemedInputContext);
    let inputRef = ref;
    if (!ref) {
      inputRef = useRef<HTMLInputElement>(null);
    }
    const [isValid, setIsValid] = useState(true);
    const [touched, setTouched] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");

    const shouldRenderIcon = role === "dropdown";
    const getRoleIcon = (): string => {
      switch (role) {
        case "dropdown":
          return "arrow-1";
      }
      return "";
    };

    const inputElement = (inputRef as React.RefObject<HTMLInputElement>)
      .current;

    const getNativeErrorMsg = (): string | undefined =>
      inputElement?.validationMessage;

    const onInputChange = (e: ChangeEvent): void => {
      const value = transformValue
        ? transformValue(inputElement?.value)
        : inputElement?.value;

      setValue(value);
      const errMsg = getNativeErrorMsg();

      if (errMsg !== validationMessage && !suppressCustomValidationMessage)
        setValidationMessage(errMsg || "");
      if (onChange) onChange(e);
    };

    const onInputBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
      const errMsg = getNativeErrorMsg();
      if (errMsg !== validationMessage && !suppressCustomValidationMessage)
        setValidationMessage(errMsg || "");
      setTouched(true);
      onBlur && onBlur(e);
    };

    useEffect(() => {
      const valid = inputElement?.validity.valid;
      if (valid !== isValid) setIsValid(!!valid);
    }, [inputElement?.value]);

    useEffect(() => {
      setIsValid(!!inputElement?.validity.valid);
      onInit && onInit(inputElement);
    }, [inputElement]);

    return (
      <Styled.Container className={className} hidden={props.hidden}>
        {children}
        <Styled.Input
          ref={inputRef}
          name={name}
          type={type}
          placeholder={placeholder}
          customTheme={themeContext}
          onChange={onInputChange}
          onBlur={onInputBlur}
          value={value}
          invalid={(!isValid && touched) || isInvalid}
          {...props}
        />
        {shouldRenderIcon && (
          <Styled.Icon
            customTheme={themeContext}
            name={getRoleIcon()}
            inputRole={role}
          />
        )}
        {!isValid && touched && validationMessage && (
          <Styled.Error>{validationMessage}</Styled.Error>
        )}
      </Styled.Container>
    );
  }
);
