export const getParamString = function(data?: {
  [key: string]: string | number | boolean | undefined;
}): string {
  if (!data) return "";

  let str = "";
  for (const key in data) {
    if (typeof data[key] !== "undefined") {
      if (str != "") {
        str += "&";
      }

      str += key + "=" + encodeURIComponent(data[key] ?? "");
    }
  }

  return str.length > 0 ? "?" + str : "";
};
