import React from "react";
import { Helmet } from "react-helmet";
import { TImage } from "src/components/OxImage/OxImage";
import { IGatsbyImageData } from "gatsby-plugin-image";

type TOxImagePreloadProps = {
  fluidImages: TImage[];
  image: string | IGatsbyImageData;
  eagerLoad?: boolean;
  sizes?: string;
};

export const OxImagePreload = ({
  fluidImages,
  image,
  eagerLoad,
  sizes,
}: TOxImagePreloadProps): JSX.Element => {
  if (!eagerLoad) return <></>;

  const getPreloadTag = ({
    index,
    ...props
  }: {
    index: number;
    href: string;
    imageSrcset?: string;
    imageSizes?: string;
    media?: string;
  }): JSX.Element => (
    <link
      key={`ox-image-preload-${index}`}
      {...props}
      rel="preload"
      as="image"
    />
  );

  return (
    <Helmet>
      {fluidImages.length > 0 &&
        fluidImages.map(({ media, images }, index) =>
          getPreloadTag({
            index,
            media: media ?? "",
            href: images.fallback?.src ?? "",
            imageSrcset: images.fallback?.srcSet ?? "",
            imageSizes: sizes ?? images.fallback?.sizes ?? "",
          })
        )}
      {typeof image === "string" &&
        getPreloadTag({ href: image ?? "", index: 0 })}
    </Helmet>
  );

  // Manual implementation of responsive image lazyloading which should probably
  // return (
  // <Helmet
  //   script={[
  //     {
  //       type: "text/javascript",
  //       innerHTML: `
  //         (function (fluidImages, preloadFallbackUrl, getViewportImage, sizes) {
  //           const image = new Image();
  //           let url = preloadFallbackUrl;
  //
  //           if (fluidImages.length > 0) {
  //             const viewportImage = getViewportImage(fluidImages);
  //
  //             image.srcset = viewportImage?.images?.fallback?.srcset;
  //             image.sizes = sizes ?? viewportImage?.images?.fallback?.sizes;
  //             url = viewportImage?.images?.fallback?.src;
  //           }
  //
  //           image.src = url;
  //         })(${JSON.stringify(
  //           fluidImages
  //         )}, "${preloadFallbackUrl}", ${getViewportImage}, "${
  //         sharedProps.sizes
  //       }");
  // `,
  //     },
  //   ]}
  // />
  // );
};
